@mixin mobile {
    @media screen and (max-width: 767px) {
        @content;
    }
}

@mixin mobileS {
    @media screen and (max-width: 480px) {
        @content;
    }
}

@mixin mobileXS {
    @media screen and (max-width: 320px) {
        @content;
    }
}

@mixin notMobile {
    @media screen and (min-width: 767px) {
        @content;
    }
}
