@import 'styles/mixins';

.body {
    background: black;
    min-height: 100vh;
}

.stickyHeader {
    animation: fadeInMove 1s cubic-bezier(0, 0, 0, 0.96) 0.5s forwards;
    opacity: 0;

    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    z-index: 999;
    top: 32px;
    left: 0;
    right: 0;
    width: min(92%, 430px);
    margin: 0px auto;
    padding: 4px;
    border-radius: 30px;
    border: 0.25px solid rgba(0, 0, 0, 0.25);
    background: rgba(0, 0, 0, 0.1);
    box-shadow: 0px 6px 18px -2px rgba(0, 0, 0, 0.1), 0px 1px 2px 0px rgba(255, 255, 255, 0.35) inset;
    backdrop-filter: blur(25px);

    .stickyHeaderButtons {
        display: flex;
        justify-content: center;
        align-items: center;

        .stickyHeaderButtonLogIn {
            display: flex;
            padding: 8px 16px;
            justify-content: center;
            align-items: center;
            gap: 12px;
            border-radius: 24px;
            border: none;
            background: transparent;

            color: #E8E8E8;
            font-size: 13px;
            font-style: normal;
            font-weight: 510;
            line-height: 16px; /* 123.077% */
        }

        .stickyHeaderButtonGetStarted {
            display: flex;
            padding: 8px 16px;
            justify-content: center;
            align-items: center;
            border-radius: 24px;
            border: none;
            // background: radial-gradient(151.68% 100% at 50% 0%, #233E66 0%, #000 100%), radial-gradient(151.68% 100% at 50% 0%, #FFF 0%, #FCFCFC 100%);
            background: radial-gradient(151.68% 100% at 50% 0%, #007aff 0%, #075a9e 100%);
            box-shadow: 0px 1.5px 6px 0px rgba(0, 0, 0, 0.12);
            color: #fff;
            font-size: 13px;
            font-weight: bold;
            line-height: 16px; /* 123.077% */
            transition: all 200s;

            &:hover {
                background: radial-gradient(151.68% 100% at 50% 0%, hsl(216, 49%, 34%) 0%, #000 100%),
                    radial-gradient(151.68% 100% at 50% 0%, #fff 0%, #fcfcfc 100%);
                box-shadow: 0px 1.5px 6px 0px rgba(0, 0, 0, 0.2);
            }
        }
    }

    .stickyHeaderIcons {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 16px;

        .stickyHeaderDiscoIcon {
            margin-left: 6px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 22px;
            height: 22px;
            border-radius: 32px;
            filter: drop-shadow(0px 0.97px 1.939px rgba(0, 0, 0, 0.30)) drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.05));
        }

        .stickyHeaderBaseIcon {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 6px;
            color: #E8E8E8;
            font-size: 13px;
            font-weight: 510;
            line-height: 16px; /* 123.077% */
            div {
                box-shadow: 0 0 5px 1px #00000022;
                border-radius: 6px;
            }
        }
    }

    @include mobile {
        top: 12px;
    }
}

.heroWrapper {
    max-width: 565px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 64px;
    margin: auto;
    padding-top: 160px;
    @include mobile {
        padding: 120px 15px;
    }
    .welcome {
        display: flex;
        align-items: center;
        gap: 16px;
    }
}
.heroText {
    font-size: 25px;
    font-weight: 800px;
    line-height: 120%;;
    color: #D9D9D9;
    br {
        display: block;
        content: " ";
        margin: 30px 0;
    }
}


.products {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 30px;
    @include mobile {
        flex-direction: column;
    }
    .product {
        display: flex;
        flex-direction: column;
        gap: 15px;
        font-size: 18px;
        color: #b9b9b9;
        text-align: center;
    }
}

@keyframes opacityFadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeInMove {
    0% {
        opacity: 0;
        transform: translateY(-20px);
    }
    30% {
        opacity: 0;
        transform: translateY(-20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
